exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-education-jsx": () => import("./../../../src/pages/education.jsx" /* webpackChunkName: "component---src-pages-education-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-education-post-js": () => import("./../../../src/templates/education-post.js" /* webpackChunkName: "component---src-templates-education-post-js" */),
  "component---src-templates-room-template-js": () => import("./../../../src/templates/room-template.js" /* webpackChunkName: "component---src-templates-room-template-js" */)
}

